import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { useSearchParams,useNavigate } from "react-router-dom";
import _ from "lodash";
import { PlusIcon } from "@heroicons/react/outline";
import { loadStripe } from "@stripe/stripe-js";
import {
  fetchDashboardData,
  fetchDashboardQuizTaken,
  fetchDashboardLeaderBoard,
  fetchStdCustomOwnAchievements,
  fetchUserUpcomingExams,
  fetchTeacherQuizInvitation,
  fetchQuizInvitation,
  fetchuserActivities,
  handleDashboardQuizModal,
  inviteMemberForSetModule,
  fetchLastCourseCreated
} from "@dashboardaction";
import { createSetModule, attemptSetQstModule } from "@questionsetsaction";
import { fetchStudentAllClass } from "@studentsaction";
import {
  teacherStudentsList,
  fetchAllClassAndChapterList,
  fetchUserQuizList,
  
} from "@mainaction";

import {
  fetchLoginUserDetails,
  subscription,
} from "@settingsaction";

import { selectedClassTeacher } from "@myspaceaction";
import { selectedClass } from "@classesaction";
import { LOCAL_TO_GMT_TIME, STRIPE_PUBLISHABLE_KEY } from "@constant";
import ContentHeader from "@contentheader";
import Addset from "@questionsetadd";
import Loader from "@loader";
import LocaleStrings from "@language";
import QuestionsetAttempt from "@questionsetattempt";
import TeacherBasic from "./components/teacher-basic";
import TeacherQuizInvitation from "./components/teacher-quiz-invitation";
import TeacherLineChart from "./components/line-chart";
import StudentBasic from "./components/student-basic";
import Studentrewards from "./components/students-rewards";
import StuQuizInvitation from "./components/student-quiz-invitation";
import Mycourse from "./components/my-course";
import TeacherCarousel from "./components/teacherCarousel";
import UpcomingExam from "./components/upcoming-exam";
import QuizOverview from "./components/quiz-overview";
import Myactivities from "./components/my-activities";
import Leaderboard from "./components/leaderboard";
import QuizSelectModal from "./components/quiz-selection-modal";
import dashboardimg from "../../assets/image/headerimages/dashboard.svg";
import img from "../../assets/image/RUBRIQUEKOJY-CHAT.png"
import img2 from "../../assets/image/RUBRIQUEKOJY-SOLVE.png"
import img3 from "../../assets/image/RUBRIQUE_KOJY_REDAC.png"
import img4 from "../../assets/image/RUBRIQUEKOJY-QUIZZ.png"
import img5 from "../../assets/image/RUBRIQUEKOJY-TRAD.png"
import img6 from "../../assets/image/PASTILLEKOJY-BOT.png"

import VideoCarousel from "./components/videoCarousel";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { usePopup } from '../../popup-fragment/popupcontext.js';

import AiTraductor from "../ai/index.js";
import AiChat from "../ai/page/freechat.js";
import AiRedaction from "../ai/page/redaction.js";
import AiQuestionGenerate from "../ai/page/questionGenerate.js";
import AiSolveDocument from "../ai/page/kojyto-solve.js";

const Dashboard = (props) => {
  var {
    session,
    allClassAndItsChapter,
    dashboardData,
    teacherQuizInvitationList,
    dashboardQuizTakenData,
    dashboardLeaderboardData,
    studentCustomOwnAchivement,
    userUpcomingExams,
    quizInvitationList,
    userAllActivities,
    createSetModal,
    attemptSetModal,
    dashboardQuizModal,
    fetchLastCourseCreated,
    userDetails
  } = props;
  const tqiPerPage = 10;
  const qiPerPage = 5;
  const uePerPage = 5;
  const saPerPage = 10;
  const coursePerPage = 4;
  const [subject,SetSubject] = useState("")
  const [lastClass,SetLastClass] = useState("")
  const [searchParams, setSearchParams] = useSearchParams();

  const sub = searchParams.has('subid') ? searchParams.get('subid') : undefined
  const navigate = useNavigate();


const ImageGrid = () => {
    const { showPopup } = usePopup();
    const imageSources = [
        '../../assets/image/RUBRIQUE KOJY-QUIZZ.png', // Image centrale
        'RUBRIQUE KOJY-QUIZZ.png', // Image en haut à gauche
        'RUBRIQUE KOJY-QUIZZ.png', // Image en haut à droite
        'RUBRIQUE KOJY-QUIZZ.png', // Image en bas à gauche
        'RUBRIQUE KOJY-QUIZZ.png', // Image en bas à droite
    ];

    return (
      <div className="relative w-full mt-[5em]">
        <div className="flex justify-center">
          <div className="relative flex-1 max-w-[292px]">
            <img
              src={img}
              alt="Image 1"
              className="w-full h-auto object-cover"
              style={{ marginTop: '0' }}
              onClick={() => showPopup(<AiChat />)}
            />
          </div>
          <div className="relative flex-1 max-w-[292px]">
            <img
              src={img2}
              alt="Image 2"
              className="w-full h-auto object-cover"
              style={{ marginTop: '50%' }}
              onClick={() => showPopup(<AiSolveDocument />)}
            />
          </div>
          <div className="relative flex-1 flex flex-col justify-start max-w-[292px]">
            <img
              src={img6}
              alt="Image Milieu Haut"
              className="w-full h-auto object-cover"
              style={{ position: 'relative', top: '-50px' }}
            />
            <img
              src={img3}
              alt="Image 3"
              className="w-full h-auto object-cover mt-auto"
              onClick={() => showPopup(<AiRedaction />)}
            />
          </div>
          <div className="relative flex-1 max-w-[292px]">
            <img
              src={img4}
              alt="Image 4"
              className="w-full h-auto object-cover"
              style={{ marginTop: '50%' }}
              onClick={() => showPopup(<AiQuestionGenerate />)}
            />
          </div>
          <div className="relative flex-1 max-w-[292px]">
            <img
              src={img5}
              alt="Image 5"
              className="w-full h-auto object-cover"
              style={{ marginTop: '0' }}
              onClick={() => showPopup(<AiTraductor />)}
            />
          </div>
        </div>
      </div>
    );
};


  useEffect(() => {


    
    props.handleDashboardQuizModal({ show: false });
    props.attemptSetQstModule({ show: false });
    props.createSetModule({ show: false });
    props.inviteMemberForSetModule({ show: false });

    //lauchStripeCheckout()

    if(sub !== undefined){
      navigate("/setting/plan?subid="+sub)
    }

    props.fetchLoginUserDetails(session,(res) =>{
      console.log(res.data);
      if(res.data.subject !== null){
        SetSubject(res.data.subject);
      }
    });

    fetchLastCourseCreated(session,(res) =>{
      if(res.data[0]){
        SetLastClass(res.data[0].classname)
      }
    })

    if (session?.usertype === "student") {
      props.fetchStdCustomOwnAchievements(session, 0, 3);
      props.fetchStudentAllClass(session, {
        userid: session?.userid,
        classchapters: true,
        questionsets: true,
      });

      /*
      props.fetchUserUpcomingExams(session, 0, uePerPage, {
        userid: session?.userid,
        today: moment(LOCAL_TO_GMT_TIME).format("YYYY-MM-DD"),
      });*/
      props.fetchQuizInvitation(session, 0, qiPerPage);
      props.fetchuserActivities(session);
    } else {
      props.fetchTeacherQuizInvitation(session, 0, tqiPerPage);
      props.teacherStudentsList(session);
      props.fetchUserQuizList(session);
      props.fetchAllClassAndChapterList(session);
      props.fetchDashboardQuizTaken(session);
    }
    props.fetchDashboardData(session);
    props.fetchDashboardLeaderBoard(session);
  }, []);

  const handleActionButton = (obj) => {
    // console.log("obj :- ", obj);

    if (obj?.key === "create") {
      props.createSetModule({ ...obj });
    } else if (obj?.key === "invite") {
      props.inviteMemberForSetModule({ ...obj });
    } else if (obj?.key === "practice_quiz" || obj?.key === "assessment_quiz") {
      props.handleDashboardQuizModal(obj);
    }
  };

  const additionCallback = () => {};

  const handleAttemptedSetCallback = () => {
    props.fetchDashboardData(session);
    props.fetchDashboardLeaderBoard(session);
    props.fetchStdCustomOwnAchievements(session, 0, 3);
    props.fetchuserActivities(session);
  };

  // console.log("allClassAndItsChapter :- ", allClassAndItsChapter);
  // console.log("dashboardData :- ", dashboardData);
  // console.log("teacherQuizInvitationList :- ", teacherQuizInvitationList);
  // console.log("dashboardQuizTakenData :- ", dashboardQuizTakenData);
  // console.log("dashboardLeaderboardData :- ", dashboardLeaderboardData);
  // console.log("quizInvitationList :- ", quizInvitationList);
  // console.log("dashboardQuizModal :- ", dashboardQuizModal);

  return (
    <>
      <ContentHeader
        customData={{
          section: "dashboard",
          subsection: "own",
          image: dashboardimg,
          breadcrumbs: [
            {
              key: "dashboard",
              text: LocaleStrings.dashboard,
              subject: session?.usertype === "teacher" && subject ? `Matière enseignée ${subject}` : undefined,
              lastClass: session?.usertype === "teacher" && lastClass ? `classe ${lastClass}` : undefined,
              ...(session?.usertype === "student"
                ? {
                    customText: `${LocaleStrings.good_morning}, ${
                      session?.username?.split(" ")[0]
                    }`,
                  }
                : {
                    customText: `${LocaleStrings.good_morning}, ${
                      session?.username?.split(" ")[0]
                    }`,
                  }),
            },
          ],
          button:
            session.usertype === "teacher"
              ? [
                  {
                    key: "invite",
                    name: LocaleStrings.button_invite_set,
                    class: "default-button-md",
                    icon: "",
                  },
                  {
                    key: "create",
                    name: LocaleStrings.button_new_quiz,
                    class: "theme-button-md",
                    icon: PlusIcon,
                  },
                ]
              : [
                  {
                    key: "practice_quiz",
                    name: LocaleStrings.button_practice_quiz,
                    class: "default-button-md",
                    icon: "",
                  },
                  {
                    key: "assessment_quiz",
                    name: LocaleStrings.button_take_quiz,
                    class: "green-button-md",
                    icon: PlusIcon,
                  },
                ],
        }}
        handleAction={handleActionButton}
      />
      {session?.usertype === "teacher" ? (
        <>
          {dashboardData?.data &&
          teacherQuizInvitationList?.data &&
          dashboardQuizTakenData?.data &&
          dashboardLeaderboardData?.data ? (
            <div className="content-body h-full">
              <TeacherBasic />

              <div className="mt-8 2xs:mt-10">
                <TeacherCarousel />
              </div>
              {/* Quiz invitation */}
              <div className="mt-8 2xs:mt-10">
                <TeacherQuizInvitation perPageList={tqiPerPage} />
              </div>

              {/* Line Chart */}
              {/*<div className="mt-8 2xs:mt-10">
                <TeacherLineChart />
              </div>*/}
              
              {/* Leaderboard */}
              <div className="mt-8 2xs:mt-10">
                <Leaderboard />
              </div>
            </div>
          ) : (
            <Loader />
          )}

          {createSetModal?.show ? (
            <Addset finishCallback={additionCallback} />
          ) : (
            ""
          )}
        </>
      ) : (
        <>
          {dashboardData?.data &&
          studentCustomOwnAchivement?.data &&
          allClassAndItsChapter?.data &&
          quizInvitationList?.data &&
          userAllActivities?.data ? (
            <div className="content-body">
              <StudentBasic perPageList={saPerPage} />
              <ImageGrid />
              {/* <Studentrewards perPageList={saPerPage} /> */}
              <div className="">
                <Mycourse />
              </div>

              <VideoCarousel />

              {session?.mqstudent === false ? (
                <div className="mt-8 2xs:mt-10">
                  <StuQuizInvitation perPageList={qiPerPage} />
                </div>
              ) : (
                ""
              )}

              {/* Quiz invitation & Course */}
              {/* <div className="grid grid-cols-12 md:gap-5">
                <div className="mt-8 col-span-12 md:col-span-6">
                  <StuQuizInvitation perPageList={qiPerPage} />
                </div>

                <div className="mt-8 col-span-12 md:col-span-6">
                  <Mycourse perPageList={coursePerPage} />
                </div>
              </div> */}

              {/* Upcoming exams */}
              {session?.mqstudent === false && userUpcomingExams?.count > 0 ? (
                <div className="mt-8 2xs:mt-10">
                  <UpcomingExam perPageList={uePerPage} />
                </div>
              ) : (
                ""
              )}

              {/* Quiz overview & My activities */}
              <div className="grid grid-cols-12 md:gap-5">
                <div className="mt-8 2xs:mt-10 col-span-12 lg:col-span-6">
                  <QuizOverview />
                </div>

                <div className="mt-8 2xs:mt-10 col-span-12 lg:col-span-6">
                  <Myactivities />
                </div>
              </div>

              {/* Leaderboard */}
              <div className="mt-8 2xs:mt-10">
                <Leaderboard />
              </div>
            </div>
          ) : (
            <Loader />
          )}

          {attemptSetModal?.show ? (
            <QuestionsetAttempt
              callfrom="dashboard"
              callbackAction={handleAttemptedSetCallback}
            />
          ) : (
            ""
          )}
          {dashboardQuizModal?.show ? <QuizSelectModal /> : ""}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  var allClassAndItsChapter =
    state?.session?.usertype === "student"
      ? state.studentAllClassList
      : state.allClassAndItsChapter;
  return {
    session: state.session,
    allClassAndItsChapter,
    createSetModal: state.createSetModal,
    attemptSetModal: state.attemptSetModal,
    dashboardData: state.dashboardData,
    teacherQuizInvitationList: state.teacherQuizInvitationList,
    dashboardQuizTakenData: state.dashboardQuizTakenData,
    dashboardLeaderboardData: state.dashboardLeaderboardData,
    studentCustomOwnAchivement: state.studentCustomOwnAchivement,
    userUpcomingExams: state.userUpcomingExams,
    quizInvitationList: state.quizInvitationList,
    userAllActivities: state.userAllActivities,
    dashboardQuizModal: state.dashboardQuizModal,
    userDetails: state.userDetails,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      teacherStudentsList,
      fetchUserQuizList,
      fetchDashboardData,
      fetchDashboardQuizTaken,
      fetchDashboardLeaderBoard,
      fetchAllClassAndChapterList,
      fetchStdCustomOwnAchievements,
      fetchTeacherQuizInvitation,
      fetchQuizInvitation,
      fetchuserActivities,
      fetchStudentAllClass,
      selectedClass,
      selectedClassTeacher,
      createSetModule,
      attemptSetQstModule,
      handleDashboardQuizModal,
      inviteMemberForSetModule,
      fetchLoginUserDetails,
      fetchLastCourseCreated,
      subscription
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
