/* eslint-disable no-restricted-globals */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import React, { useState, useEffect, useRef, memo, createRef } from 'react';
import { add, forEach } from "lodash";
import PdfImage from "../../../assets/image/pdf.png";
import Markdown from "../../Markdown.js";
import TabsMessage from '../component/tabsMessage.js';
import ReactDOMServer from "react-dom/server";
import Spinner from "../loading/spinner.js";
import MessageList from "../component/messageList.js";
// eslint-disable-next-line import/no-webpack-loader-syntax
import wowo from "worker-loader!../streamWorker.js";
//import 'katex/dist/katex.min.css';

import ActionsComponent from "../actionComponent.js";
import FeedBack from "../component/feedback.js";
//on prend une div
const worker = new wowo();

//tenter une approche avec un tableau de deiv pour les messages et éviter l'update complet de la page

function useAutosizeTextarea(value,messages) {
    const textAreaRef = useRef(null);

    useEffect(() => {
        console.log(messages);
        if(Object.keys(messages).length == 1){
            return textAreaRef.current.style.height = '200px';
        }


        if (textAreaRef.current) {
            textAreaRef.current.style.height = '0px';
            const { scrollHeight } = textAreaRef.current;
            if (scrollHeight > 270) {
                return textAreaRef.current.style.height = `270px`;
            }
            textAreaRef.current.style.height = `${scrollHeight + 5}px`;
        }
    }, [value,messages]);

    return textAreaRef;
}



function Redaction({ session, userDetails }) {
    const [userInput, setUserInput] = useState('');
    const chatBoxRef = useRef(null);
    const inputFileRef = createRef();
    const [autoScroll, setAutoScroll] = useState(true);
    const [messages, setMessages] = useState({
        0: { type: 'ia', message: "<h4>Assistance à l’écriture de dissertation ou rédaction en français, philosophie, histoire-géographie, économie…</h4>" }
    });
    const [fileUrl, setFileUrl] = useState([]);
    const textAreaRef = useAutosizeTextarea(userInput,messages);
    const userData = userDetails.data;
    const [userLevelInput, setUserLevelInput] = useState(userData?.level ?? '');
    const [userSubjectInput, setUserSubjectInput] = useState('');
    const [enabled, setEnabled] = useState(false);


    const handleInputChange = (e) => {
        setUserInput(e.target.value);
    };

    function imageLoaded(e) {
        chatBoxRef.current.scrollTo(0, chatBoxRef.current.scrollHeight - chatBoxRef.current.clientHeight);
    }

    const convertLatexDelimiters = (text) => {
        text = text 
        .replace(/\\\[/g, '$$$')  // Replace all occurrences of \[ with $$
        .replace(/\\\]/g, '$$$') // Replace all occurrences of \] with $$
        .replace(/\\\(/g, '$$')  // Replace all occurrences of \( with $$
        .replace(/\\\)/g, '$$'); // Replace all occurrences of \) with $$
        return text;
    };
    window.imageLoaded = imageLoaded;//for call function for html 

    const SimpleFetch = async (url, type = "POST", data = {}) => {
        const response = await fetch(url, {
            method: type,
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          });
        
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
        
          return await response.json();
    }

    worker.onmessage = (event) => {
        let { id, text, baseContent, additionnalData } = event.data;
        if(additionnalData !== undefined){
            additionnalData = JSON.parse(additionnalData);
        }
        setMessagesForStream(text, id, baseContent, additionnalData);
    };

    const setMessagesForStream = (message, id, baseContent, additionnalData) => {
        setMessages((prevMessages) => ({
            ...prevMessages,
            [id]: { type: 'ia', message: message, baseContent: baseContent, additionnalData : additionnalData },
        }));
        if (autoScroll) {
            chatBoxRef.current.scrollTo(0, chatBoxRef.current.scrollHeight - chatBoxRef.current.clientHeight);
        }
    };
    const handleSend = async (e) => {
        const params = { 
            "createThread" : Object.keys(messages).length == 1 ? true : false,
            "level" : userLevelInput,
            "subject" : userSubjectInput
        };
        setAutoScroll(true);
        e.preventDefault();
        
        if (!session.userid) return;

        let html = "";
        forEach(fileUrl, (url) => {
            html += `<img src="${getUrlForExtension(url)}" onload="window.imageLoaded()" />`;
        });

        
        const id = Object.keys(messages).length;
        setMessages((prevMessages) => ({
            ...prevMessages,
            [id]: { type: 'user', message:  "<div>" + userInput + html + "</div>" },
        }));
       
        
        /*worker.postMessage({
            url:"https://ai.kojyto.fr/api/v1/chat",
            fileUrl,
            userInput,
            userid: session.userid,
            messageId: id + 1,
            params
        });*/
        setMessages((prevMessages) => ({
            ...prevMessages,
            [id + 1]: { type: 'ia', message: ReactDOMServer.renderToString(<><h3>Réponse en cours de génération <Spinner></Spinner></h3></>) },
        }));

        setTimeout(() => {
            chatBoxRef?.current?.scrollTo(0, chatBoxRef.current.scrollHeight - chatBoxRef.current.clientHeight);
        }, 50);
        
        setUserInput("");
        var resp = await SimpleFetch("https://ai.kojyto.fr/api/v1/redaction", "POST", {userid:session.userid,question:userInput, augmented:enabled})
        //convertLatexDelimiters(resp.data)
        setMessages((prevMessages) => ({
            ...prevMessages,
            [id + 1]: { type: 'ia', message: ReactDOMServer.renderToString(<TabsMessage tabs={resp.data.tabs} className="markdown"></TabsMessage>)},
        }));
        
        setFileUrl([]);
    };


    function stopAutoScroll(e) {
        const wheelDirection = e.deltaY < 0 ? 'up' : 'down';
        if (wheelDirection == 'up') {
            setAutoScroll(false);
        } else if (wheelDirection == 'down') {
            const chat = chatBoxRef.current
            const isScrolledToBottom = chat.scrollHeight - chat.scrollTop  - chat.clientHeight <= chat.clientHeight + 1;
            if (isScrolledToBottom) {
                setAutoScroll(true);
            }
        }
    }

    const removeFileUrl = (urlToRemove) => {
        setFileUrl((prevUrls) => prevUrls.filter((url) => url !== urlToRemove));
    };

    const getUrlForExtension = (url) => {
        const extension = url.split('.').pop().toLowerCase();
        return extension === "pdf" ? PdfImage : url;
    };


    return (
        <div className="h-max-[100vh] flex items-center justify-center bg-gray-50">
            <div className="w-full max-w-[98%] p-4 sm:p-6 bg-white rounded-lg shadow-md">
                <div className="flex flex-col items-center">
                    <div className="text-2xl sm:text-3xl lg:text-4xl font-bold mb-2 text-center">Kojyto rédaction 🎓</div>
                </div>
                
                <div>
                    <MessageList 
                        chatBoxRef={chatBoxRef}
                        messages={messages}
                        introductionMessageLength={1}
                        dynamicActions={[]}
                    />

                    <div className="mt-3 w-[80%] m-auto">
                        {fileUrl.map((url, index) => (
                            <div key={index} className="w-20 h-20 rounded relative inline-block ml-2">
                                <svg
                                    onClick={() => removeFileUrl(url)}
                                    className="absolute right-0"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="red"
                                    width="24px"
                                    height="24px"
                                >
                                    <path d="M19.414 4.586a2 2 0 00-2.828 0L12 9.172 7.414 4.586a2 2 0 00-2.828 2.828L9.172 12l-4.586 4.586a2 2 0 002.828 2.828L12 14.828l4.586 4.586a2 2 0 002.828-2.828L14.828 12l4.586-4.586a2 2 0 000-2.828z" />
                                </svg>
                                <img className="w-20 h-20 rounded" src={getUrlForExtension(url)} />
                            </div>
                        ))}
                    </div>


                    <div className="flex items-center gap-3 mb-2">
                        <span className="text-sm font-medium text-gray-900">{"Rédaction"}</span>
                        <button
                            onClick={() => setEnabled(!enabled)}
                            className={`relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 ${
                                enabled ? 'bg-indigo-600' : 'bg-gray-200'
                            }`}
                            role="switch"
                            aria-checked={enabled}
                        >
                            <span
                                className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${
                                enabled ? 'translate-x-5' : 'translate-x-0'
                                }`}
                            />
                        </button>
                        <span className="text-sm font-medium text-gray-900">{"Dissertation"}</span>
                    </div>

                    <div className="flex flex-col space-y-4">
                        <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-4 space-y-4 sm:space-y-0">
                            <textarea
                                ref={textAreaRef}
                                className="w-full p-4 bg-gray-100 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-500"
                                placeholder="Indique moi le sujet qui t'intéresse."
                                value={userInput}
                                onChange={handleInputChange}
                                rows={3}
                            />

                            <div className="w-full sm:w-auto p-3 sm:p-4 rounded-lg text-center space-y-2">
                                <button
                                    onClick={handleSend}
                                    className="w-full sm:w-30 p-3 sm:p-4 bg-theme text-white rounded-lg text-center"
                                >
                                    Envoyer
                                </button>
                                <FeedBack />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        session: state.session,
        userDetails: state.userDetails,
    };
};
  
const mapDispatchToProps = (dispatch) =>
bindActionCreators({},
    dispatch,
);



export default connect(mapStateToProps, mapDispatchToProps)(Redaction);
